import * as React from "react";
import { DataGrid, Column, GetDataSource, TotalItem, Summary, formatMonth, useGetData, useBusy } from "../DataGrid";

export function TaxInvoiceDetail() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [date1, setDate1] = React.useState(formatMonth(new Date(), -1));

    const [count, setCount] = React.useState(0);
    const [source, setSource] = React.useState<any>([]);
    const setBusy = useBusy();
    const get = useGetData();

    const handleCheck = async (e: any) => {
        try {
            setBusy("플랜트1400 품목정보 동기화...");
            await get("/api/sap/SyncIHLParts/1400");
            setBusy("플랜트1440 품목정보 동기화...");
            await get("/api/sap/SyncIHLParts/1440");
            setBusy("플랜트1480 품목정보 동기화...");
            await get("/api/sap/SyncIHLParts/1480");
            setBusy("대체자재마스터 업데이트...");

            await get("/api/sap/SyncCustomerPartMap");
            setBusy("전월 위탁재고(1400) 업데이트...");
            await get("/api/sap/SycnConsigneeStock/1400");
            setBusy("전월 위탁재고(1440) 업데이트...");
            await get("/api/sap/SycnConsigneeStock/1440");
            setBusy("전월 위탁재고(1480) 업데이트...");
            await get("/api/sap/SycnConsigneeStock/1480");
            setBusy("오류를 검증 합니다....");
            await get("/api/TaxInvoiceDetail/Check/" + date);
            setBusy(date + " 결산파일 생성중...");
            await get("/api/SapUploadFile/Create/" + date);
        } catch { }
        setBusy();
        setCount(count + 1);
    };

    React.useEffect(() => {
        setSource(GetDataSource("TaxInvoiceDetail", "Get/" + date + "-" + date1));
    }, [date, date1, count]);

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate(e.value),
                },
            },
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date1,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate1(e.value),
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: "매출결산",
                    stylingMode: "contained",
                    onClick: handleCheck,
                },
            }
        );
    };

    return (
        <div className="layoutRoot">
            <DataGrid source={source} showGroupPanel={true} allowEditing={true} onToolbarPreparing={onToolbarPreparing} allowDeleting={false} allowAdding={false}>
                <Column dataField="IsValid" caption="마감가능" width={80} allowEditing={false}></Column>
                <Column dataField="ErrorMsg" caption="오류유형" width={100} allowEditing={false}></Column>
                <Column dataField="ErrorMsg2" caption="상세오류내역" width={150} allowEditing={false}></Column>
                <Column dataField="Plant" caption="플랜트*" width={100} allowEditing={true}></Column>
                <Column dataField="CustomerName" caption="발주사" width={200} allowEditing={false}></Column>
                <Column dataField="SalesType" caption="매출유형" width={120} allowEditing={false}></Column>
                <Column dataField="Remark" caption="매출세부내역" width={250} allowEditing={false}></Column>
                <Column dataField="PartNo" caption="고객품번" width={120} allowEditing={false}></Column>
                <Column dataField="MATNR" caption="IHL품번" width={120} allowEditing={false}></Column>
                <Column dataField="PartNo2" caption="대체결산품번*" width={120} allowEditing={true}></Column>
                <Column dataField="Name" caption="품명" width={100} allowEditing={false}></Column>
                <Column dataField="Date" caption="거래일자" allowEditing={false}></Column>
                <Column dataField="UnitQty" caption="수량" format="###,##0" allowEditing={false}></Column>
                <Column dataField="UnitPrice" caption="매출검수단가" format="###,##0.######" allowEditing={false}></Column>
                <Column dataField="Amount" caption="매출검수금액" format="###,##0" width={150} allowEditing={false}></Column>
                <Column dataField="Desc" caption="비고" allowEditing={false}></Column>
                <Column dataField="ISSUE_ID" caption="승인번호" width={220} allowEditing={false}></Column>
                <Column dataField="CustomerCode" caption="발주사코드" width={80} allowEditing={false}></Column>
                <Column allowEditing={true} dataField="Pono" caption="PO번호" />

                <Column />
                <Summary>
                    <TotalItem column="ErrorMsg" displayFormat="항목수: {0}" summaryType="count" />
                    <TotalItem column="UnitQty" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                    <TotalItem column="Amount" summaryType="sum" displayFormat="{0}원" valueFormat="###,##0" />
                </Summary>
            </DataGrid>
        </div>
    );
}
