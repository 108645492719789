/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Button, Popup, TextArea, TextBox } from "devextreme-react";
import * as React from "react";
import { useHistory } from "react-router";
import { useGetData, usePostData } from "../../DataGrid";

const classes = css`
  & {
    color: #999999;
    width: 100%;
    min-height: 140px;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 1.2rem;
        &::after {
          content: "";
          display: block;
        }
      }
    }
    .content {
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
`;

export default function Comment({ month }: { month: string }) {
  const $get = useGetData();
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState({ title: "", comment: "" });

  const role = sessionStorage.getItem("role") == "관리자";

  React.useEffect(() => {
    if (show) return;
    $get("/api/home/comment/" + month).then((res) => setData(res));
  }, [month]);

  if (!data.title) {
    return (
      <div>
        <Button icon="edit" onClick={() => setShow(true)} text="시사점을 입력하세요"></Button>

        <CommentDialog show={show} onHiding={() => setShow(false)}></CommentDialog>
      </div>
    );
  }
  return (
    <div css={classes}>
      <div className="header">
        <div className="title"> {data.title}</div>
        <Button icon="edit" onClick={() => setShow(true)} hint="시사점을 입력하세요"></Button>
      </div>
      <div className="content">{data.comment}</div>
      <CommentDialog show={show} onHiding={() => setShow(false)}></CommentDialog>
    </div>
  );
}

function CommentDialog({ show, onHiding }: { show: boolean; onHiding: () => void }) {
  const styled = css`
    .item {
      margin-bottom: 1rem;
    }
    .footer {
      display: flex;
      justify-content: center;
    }
  `;
  const [state, setState] = React.useState({ title: "", comment: "" });
  const get = useGetData();
  const post = usePostData();
  const histroy = useHistory();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    post("api/home/comment", state).then((res) => {
      onHiding();
    });
  };

  return (
    <>
      <Popup visible={show} dragEnabled={true} onHiding={onHiding} closeOnOutsideClick={true} showTitle={true} title="시사점 입력" width={460} height={320}>
        <form css={styled} onSubmit={onSubmit}>
          <div className="item">
            <TextBox
              placeholder="제목을 입력하세요"
              onValueChanged={(e: any) => {
                setState({ ...state, title: e.value });
              }}
            />
          </div>
          <div className="item">
            <TextArea
              placeholder="내용을 입력하십시오."
              height="7rem"
              onValueChanged={(e: any) => {
                setState({ ...state, comment: e.value });
              }}
            />
          </div>
          <div className="footer">
            <Button icon="check" useSubmitBehavior={true} text="저장"></Button>
          </div>
        </form>
      </Popup>
    </>
  );
}
