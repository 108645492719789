import * as React from "react";
import { DataGrid, Column, GetDataSource, Lookup } from "../DataGrid";


export function CustomerContact() {
    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource("CustomerContact")} showGroupPanel={false} showHeaderFilter={false}>
                <Column dataField="CustomerId" caption="발주사" width={310}>
                    <Lookup dataSource={GetDataSource("CodeMaster", "Customers")} displayExpr="Name" valueExpr="Id" />
                </Column>
                <Column dataField="Name" caption="담당자이름" width={280}></Column>
                <Column dataField="Mail" caption="담당자(메일)" width={220}></Column>
                <Column></Column>
            </DataGrid>
        </div>
    );
}