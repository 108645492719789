import { Summary, TotalItem } from "devextreme-react/data-grid";
import { ButtonItem, Form, Item, Label, RequiredRule, SimpleItem } from "devextreme-react/form";
import Popup from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import * as React from "react";
import { DataGrid, Column, ValueFormat, GetDataSource, usePostData } from "../DataGrid";

export function MonthlyQtyBalance() {
  const [show, setShow] = React.useState(false);

  return (
    <div className="layoutRoot">
      <DataGridView
        onUpload={() => {
          setShow(true);
        }}
      />
      <UploadDialog show={show} setShow={setShow} />
    </div>
  );
}

function DataGridView({ onUpload }: { onUpload: () => void }) {
  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        hint: "업로드",
        onClick: onUpload,
      },
    });
  };

  return (
    <DataGrid
      source={GetDataSource("MonthlyQtyBalance")}
      showGroupPanel={false}
      allowExport={true}
      allowDeleting={false}
      allowAdding={false}
      allowEditing={true}
      onToolbarPreparing={onToolbarPreparing}>
      <Column dataField="DocMonth" caption="기준연월" allowEditing={false} />
      <Column dataField="CustomerCd" caption="발주사코드" allowEditing={false}></Column>
      <Column dataField="Customer.Name" caption="발주사" allowEditing={false} width={200}></Column>
      <Column dataField="PartNo" caption="고객품번" allowEditing={false} />
      <Column dataField="PrevQty" caption="전월이월" allowEditing={false} allowFiltering={false} />
      <Column dataField="ThisQty" caption="당월출고" allowEditing={false} allowFiltering={false} />
      <Column dataField="InvoQty" caption="거래명세" allowEditing={false} allowFiltering={false} />
      <Column dataField="NextQty" caption="익월이월" allowEditing={true} allowFiltering={false} />
      <Column dataField="Diff" caption="차이수량" allowEditing={false} allowFiltering={false} />
      <Column dataField="Remark" caption="비고" allowEditing={true} allowFiltering={false}></Column>
      <Summary>
        <TotalItem column="PrevQty" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
        <TotalItem column="ThisQty" displayFormat="{0}" summaryType="sum" valueFormat="###,##0" />
        <TotalItem column="InvoQty" displayFormat="{0}" summaryType="sum" valueFormat="###,##0" />
        <TotalItem column="Diff" displayFormat="{0}" summaryType="sum" valueFormat="###,##0" />
        <TotalItem column="NextQty" displayFormat="{0}" summaryType="sum" valueFormat="###,##0" />
      </Summary>
    </DataGrid>
  );
}

function UploadDialog({ show, setShow }: { show: boolean; setShow: (p: boolean) => void }) {
  const post = usePostData();
  const [data, setData] = React.useState({ data: "" });

  const handlerSubmit = (e: Event | any) => {
    e.preventDefault();
    post("/api/MonthlyQtyBalance/Upload", data)
      .then((res) => {
        if (res.error) {
          notify(res.error, "error", 3000);
        }
      })
      .finally(() => {
        setShow(false);
      });
  };

  return (
    <Popup
      visible={show}
      dragEnabled={false}
      onHiding={() => {
        setShow(false);
      }}
      closeOnOutsideClick={true}
      showTitle={true}
      title="결산수불부 업로드"
      width={700}
      height={350}>
      <form onSubmit={handlerSubmit}>
        <Form readOnly={false} formData={data} showColonAfterLabel={true} labelLocation="top">
          <SimpleItem
            dataField="data"
            label={{ text: "업로드 양식(발주사코드, 고객품번,전월이월, 당월실적)", showColon: false }}
            editorType="dxTextArea"
            editorOptions={{ height: 190, placeholder: "제목줄을 반드시 포함하세요." }}>
            <RequiredRule message="반드시 입력하십시오" />
          </SimpleItem>

          <ButtonItem
            horizontalAlignment="right"
            buttonOptions={{
              text: "저장",
              type: "default",
              width: "130px",
              useSubmitBehavior: true,
            }}
          />
        </Form>
      </form>
    </Popup>
  );
}
