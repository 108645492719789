import * as React from "react";
import { GetDataSource, Column, DataGrid, formatMonth } from "../DataGrid";

export function ConsigneeStock() {
  const [date, setDate] = React.useState(formatMonth(new Date(), -1));
  const [data, setData] = React.useState<any>([]);

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxTextBox",
      options: {
        width: 136,
        value: date,
        mask: "0000.00",
        onValueChanged: ({ value }: { value: string }) => {
          setDate(value);
        },
      },
    });
  };

  React.useEffect(() => {
    setData(GetDataSource("ConsigneeStock"));
  }, [date]);

  return (
    <div className="layourRoot">
      <DataGrid onToolbarPreparing={onToolbarPreparing} allowEditing={false} source={data} allowAdding={false} allowDeleting={false} showGroupPanel={false} allowExport={true}>
        <Column dataField="Plant" caption="플랜트" allowEditing={false}></Column>

        <Column dataField="DocMonth" caption="전기월" width={100} allowEditing={false}></Column>

        <Column dataField="CustomerCode" caption="발주사" width={100} allowEditing={false}></Column>

        <Column dataField="PartNo" caption="IHL품번" width={150} allowHeaderFiltering={false}></Column>

        <Column dataField="Qty" caption="재고수량" allowEditing={false}></Column>

        <Column dataField="Created" caption="생성일" width={180} dataType="date" allowHeaderFiltering={false} allowEditing={false}></Column>
        <Column></Column>
      </DataGrid>
    </div>
  );
}
