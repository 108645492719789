import { Button, Chart, CheckBox, PivotGrid } from "devextreme-react";
import { AdaptiveLayout, CommonSeriesSettings, Size, Tooltip } from "devextreme-react/chart";
import { FieldChooser } from "devextreme-react/pivot-grid";
import dxPivotGrid from "devextreme/ui/pivot_grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import dxChart from "devextreme/viz/chart";
import * as React from "react";
import { connect } from "react-redux";
import { useBusy, useGetData } from "./../DataGrid";
import classes from "./SalesPivot.module.css";

export function SalesPivot() {
    const get = useGetData();
    const setBusy = useBusy();
    const [data, setData] = React.useState<any[]>([]);
    const [index, setIndex] = React.useState("");

    const initState = {
        category: true,
        item: false,
        demand: false,
        proj: false,
        light: false,
        quarter: true,
        month: false,
    };

    const [state, setState] = React.useState(initState);

    const dataSource = new PivotGridDataSource({
        fields: [
            {
                caption: "매출구분",
                width: 120,
                dataField: "Category",
                area: "row",
                sortBySummaryField: "Total",
                expanded: true,
                visible: state.category,
            },
            {
                caption: "수요",
                dataField: "Demand",
                width: 150,
                area: "row",
                expanded: true,
                visible: state.demand,
            },
            {
                caption: "프로젝트",
                dataField: "Proj",
                width: 150,
                area: "row",
                expanded: true,
                visible: state.proj,
            },

            {
                caption: "품목",
                dataField: "Item",
                width: 150,
                expanded: true,
                area: "row",
                visible: state.item,
            },
            {
                caption: "광원",
                dataField: "Light",
                width: 150,
                area: "row",
                visible: state.light,
            },

            {
                dataField: "Year",
                dataType: "number",
                area: "column",
                format: "#년",
                sortBy: "value",
                sortOrder: "asc",
                expanded: true,
            },
            {
                dataField: "Quarter",
                dataType: "number",
                area: "column",
                format: "#분기",
                sortBy: "value",
                sortOrder: "asc",
                expanded: true,
                visible: state.quarter,
            },
            {
                dataField: "Month",
                dataType: "number",
                area: "column",
                format: "#월",
                sortBy: "value",
                sortOrder: "asc",

                visible: state.month,
            },
            {
                caption: "매출액",
                dataField: "Amount",
                dataType: "number",
                summaryType: "sum",
                format: "###,###",
                area: "data",
            },
        ],
        store: data,
    });

    const currencyFormatter = new Intl.NumberFormat("ko-KR", {
        style: "currency",
        currency: "KRW",
        minimumFractionDigits: 0,
    });

    let _chart: dxChart | undefined;
    let _pivotGrid: dxPivotGrid | undefined;

    const customizeTooltip = (args: any) => {
        const valueText = currencyFormatter.format(args.originalValue);
        return {
            html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`,
        };
    };

    const loadData = async () => {
        _pivotGrid?.bindChart(_chart, {
            dataFieldsDisplayMode: "splitPanes",
            alternateDataFields: false,
        });

        setBusy("잠시만 기다려 주십시오.");
        const res = await get("/api/SalesSummary/All");
        setData(res);
        setBusy();
    };

    React.useEffect(() => {
        loadData();
    }, [0]);

    return (
        <div className="layoutRoot2">
            <div className={classes.toolbar}>
                <CheckBox className={classes.item} text="매출구분" value={state.category} onValueChange={(e) => setState({ ...state, category: e })} />
                <CheckBox className={classes.item} text="품목별" value={state.item} onValueChange={(e) => setState({ ...state, item: e })} />
                <CheckBox className={classes.item} text="수요별" value={state.demand} onValueChange={(e) => setState({ ...state, demand: e })} />
                <CheckBox className={classes.item} text="차종별" value={state.proj} onValueChange={(e) => setState({ ...state, proj: e })} />
                <CheckBox className={classes.item} text="광원" value={state.light} onValueChange={(e) => setState({ ...state, light: e })} />

                <CheckBox className={classes.item} text="분기 집계" value={state.quarter} onValueChange={(e) => setState({ ...state, quarter: e })} />
                <CheckBox className={classes.item} text="월별 집계" value={state.month} onValueChange={(e) => setState({ ...state, month: e })} />
            </div>
            <PivotGrid
                id="pivotgrid"
                className={classes.pivot}
                allowExpandAll={true}
                dataSource={dataSource}
                allowSortingBySummary={true}
                allowFiltering={true}
                showBorders={true}
                showColumnTotals={false}
                showColumnGrandTotals={false}
                showRowTotals={false}
                showRowGrandTotals={true}
                ref={(ref) => (_pivotGrid = ref?.instance)}>
                <FieldChooser enabled={true} height={400} />
            </PivotGrid>
            <Chart ref={(ref) => (_chart = ref?.instance)} className={classes.chart}>
                <Size height={450} />
                <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
                <CommonSeriesSettings type="spline" />
                <AdaptiveLayout width={450} />
            </Chart>


        </div>
    );
}
