import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import TextArea from "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import * as React from "react";
import { Column, DataGrid, formatMonth, GetDataSource, useBusy, usePostData, Summary, TotalItem } from "../DataGrid";

export function HkmcD9() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [show, setShow] = React.useState(false);
    const setBusy = useBusy();
    const post = usePostData();

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 136,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: ({ value }: { value: string }) => {
                        setDate(value);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "add",
                    hint: "파일 업로드",
                    onClick: () => {
                        setShow(true);
                    },
                },
            }
        );
    };

    const uploadAction = async (value: string) => {
        try {
            setBusy("D9파일 업로드 중입니다...");
            var res = await post("/api/HkmcD9/Upload", { content: value });
            setShow(false);
            if (res.error) {
                notify(res.error, "error", 5000);
            }
        } catch { }
        setBusy();
    };

    return (
        <div className="layoutRoot">
            <DataGrid
                source={GetDataSource("HkmcD9", "Get/" + date)}
                showGroupPanel={false}
                allowEditing={false}
                allowExport={true}
                onToolbarPreparing={onToolbarPreparing}
                allowDeleting={true}
                allowAdding={false}>
                <Column dataField="Id" caption="Id"></Column>
                <Column dataField="DocNo" caption="DOCUMENT CODE" width={160}></Column>
                <Column dataField="Plant" caption="공장"></Column>
                <Column dataField="DocMonth" caption="분석기간-월"></Column>
                <Column dataField="PartNo" caption="품번" width={150}></Column>
                <Column dataField="Unit" caption="기본단위"></Column>
                <Column dataField="Gubun" caption="입고구분"></Column>
                <Column dataField="Qty" caption="입고누계수량" format="###,##0" width={150}></Column>
                <Column dataField="Amount" caption="입고누계금액" format="###,##0" width={150}></Column>
                <Column dataField="PackCost" caption="포장비누계" format="###,##0" width={150}></Column>
                <Column dataField="Created" caption="생성일" width={220}></Column>
                <Column></Column>
                <Summary>
                    <TotalItem column="Qty" displayFormat="{0}" summaryType="sum" valueFormat="###,##0" />
                    <TotalItem column="Amount" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                    <TotalItem column="PackCost" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                </Summary>
            </DataGrid>
            <UploadForm
                show={show}
                onHiding={() => {
                    setShow(false);
                }}
                uploadAction={uploadAction}
            />
        </div>
    );
}

type UploadFormPropType = {
    uploadAction?: (value: string) => void;
    onHiding: () => void;
    show: boolean;
};

function UploadForm(props: UploadFormPropType) {
    const [value, setValue] = React.useState("");

    return (
        <Popup visible={props.show} onHiding={props.onHiding} dragEnabled={false} closeOnOutsideClick={true} showTitle={true} title="HKMC D9 업로드" width={700} height={450}>
            <div>제목줄까지 포함해서 붙여넣으십시오.</div>

            <TextArea
                height={240}
                onValueChanged={(e) => setValue(e.value)}
                style={{ marginTop: "0.5rem" }}
                value={value}
                placeholder="DOCUMENT NO/분석기간-월/품번/기본단위 /입고구분/입고누계수량/입고누계금액/포장비누계"></TextArea>

            <div>
                <Button
                    text="업로드"
                    style={{ marginTop: "16px", marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => {
                        if (props.uploadAction) {
                            props.uploadAction(value);
                            setValue("");
                        }
                    }}
                />
            </div>
        </Popup>
    );
}
