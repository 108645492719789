import notify from "devextreme/ui/notify";
import * as React from "react";
import { DataGrid, GetDataSource, Column, Lookup, ValueFormat, useBusy, usePostData, formatMonth, useGetData } from "../DataGrid";

// 매/출입 서열비 관리
export function SalesCost3() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [date1, setDate1] = React.useState(formatMonth(new Date(), -1));


    const [refresh, setRefresh] = React.useState(new Date());

    const datasource = React.useMemo(() => GetDataSource("SalesCost3", "Get/" + date + "-" + date1), [date, date1, refresh]);
    const get = useGetData();
    const setBusy = useBusy();

    const createHandler = async () => {
        try {
            setBusy("생성중입니다 잠시만 기다려 주십시오");
            var res = await get("/api/SalesCost3/Create/" + date);
            if (res.error) {
                notify(res.error, "error", 5000);
            }
        } catch { }
        setBusy();
        setRefresh(new Date());
    };

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate(e.value),
                },
            },
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date1,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate1(e.value),
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "add",
                    text: "생성",
                    onClick: createHandler,
                },
            }
        );
    };
    return (
        <div className="layoutRoot">
            <DataGrid source={datasource} allowAdding={false} allowEditing={false} showGroupPanel={false} allowDeleting={false} onToolbarPreparing={onToolbarPreparing}>
                <Column dataField="Id" caption="Id"></Column>
                <Column caption="결산월" dataField="DocMonth"></Column>
                <Column caption="판매사" dataField="Buyer"></Column>
                <Column caption="발주사" dataField="Customer"></Column>

                
                <Column caption="수요" dataField="Demand"></Column>
                <Column caption="고객품번" dataField="PartNo"></Column>
                <Column caption="결산수량" dataField="Qty"></Column>

                <Column caption="서열 협의단가" dataField="OrderAgreeCost"></Column>
                <Column caption="협의/결산단가 차이" calculateCellValue={(data:any)=> data.OrderAgreeCost - data.UnitCost  }></Column>


                <Column caption="매출단가(산출)" dataField="UnitPrice"></Column>
                <Column caption="매출금액" dataField="PriceAmt"></Column>
                <Column caption="서열업체" dataField="Vendor"></Column>
                <Column caption="매입단가" dataField="UnitCost"></Column>
                <Column caption="매입금액" dataField="CostAmt"></Column>

                <Column caption="단가차이" dataField="Diff"></Column>


                <Column caption="구매율" dataField="PurchaseRate"></Column>
                <Column caption="비고" dataField="Commnet"></Column>

                <Column />
            </DataGrid>
        </div>
    );
}
