import * as View from "./";
import SalesData from "./SalesData";
import {HomeView} from "./home/HomeView";
import {MonthlyQtyBalance} from "./MonthlyQtyBalance";


export { Part } from './Part';
export { Territory } from './Territory';
export { Vendor } from './Vendor';
export { Customer } from './Customer';
export { CustomerPart } from './CustomerPart';
export { SalesCost } from './SalesCost';
export { TaxInvoice } from './TaxInvoice';
export { TaxInvoiceDetail } from './TaxInvoiceDetail';
export { User } from './User';
export { SapUploadFile } from "./SapUploadFile";
export { ConsigneeStock } from "./ConsigneeStock";
export { CustomerPartMap } from "./CustomerPartMap";
export { HkmcD9 } from "./HkmcD9";
export { Demand } from "./Demand";
export { InspecDataCLM} from "./InspecDataCLM";
export { SalesPivot } from "./SalesPivot"
export { Error401, Error403 } from "./ErrorPages"
export { SSOLogin } from "./SSOLogin";



 const SiteMap = [
    { id: 1, group: "", title: "Home",level:0,  path: "/", component: HomeView },
  
    { id: 2, group: "매출현황", level: 0, title: "매출계획/실적현황(세금계산서 기준)", path: "/Home", component: HomeView },
    { id: 3, group: "매출현황", level: 0, title: "매출실적 세부현황(세금계산서 기준)", path: "/SalesPivot", component: View.SalesPivot },
  
    { id: 21, group: "매출결산", level: 2, title: "세금계산서 접수/검토", path: "/TaxInvoice", component: View.TaxInvoice },
    { id: 22, group: "매출결산", level: 2,  title: "거래명세서 검토", path: "/TaxInvoiceDetail", component: View.TaxInvoiceDetail },
    { id: 23, group: "매출결산", level: 2,title: "SAP 매출 등록정보", path: "/SapUploadFile", component: View.SapUploadFile },
    { id: 24, group: "매출결산", level: 1,title: "매출결산 현황(수요구분)", path: "/SalesData", component: SalesData },
    { id: 25, group: "매출결산", level: 1,title: "출고/매출결산 수불현황", path: "/MonthlyQtyBalance", component: MonthlyQtyBalance },

    { id: 31, group: "매출/입 비용관리", level: 1,title: "매출/입 포장비 관리", path: "/SalesCost2", component: View.SalesCost2 },
     { id: 32, group: "매출/입 비용관리", level: 1,title: "매출/입 서열비 관리", path: "/SalesCost3", component: View.SalesCost3 },
     { id: 33, group: "매출/입 비용관리", level: 1,title: "업무대행 관리비 산출/관리", path: "/SalesCost4", component: View.SalesCost4 },
  
    { id: 1023, group: "고객 검수",level: 0, title: "CKD 검수(HKMC-D9)", path: "/HkmcD9", component: View.HkmcD9 },
    { id: 1024, group: "고객 검수",level: 0, title: "CKD 검수(글로비스/기타)", path: "/InspecDataCLM", component: View.InspecDataCLM },
  
    { id: 100, group: "ERP정보 조회", level: 2, title: "SAP 자재마스터 I/F정보", path: "/Part", component: View.Part },
    { id: 101, group: "ERP정보 조회", level: 2, title: "SAP 대체자재마스터 I/F정보", path: "/CustomerPartMap", component: View.CustomerPartMap },
    { id: 102, group: "ERP정보 조회", level: 2, title: "SAP 위탁재고 월현황 I/F정보", path: "/ConsigneeStock", component: View.ConsigneeStock },
  
    { id: 16, group: "기준정보",level: 0, title: "품목정보관리현황", path: "/CustomerPart", component: View.CustomerPart },
     { id: 161, group: "기준정보", level: 2, title: "SAP 수요정보", path: "/Demand", component: View.Demand },

     { id: 15, group: "기준정보", level: 2, title: "판매/발주사 현황", path: "/Customer", component: View.Customer },
     { id: 153, group: "기준정보", level: 2, title: "세금계산서 접수담당자", path: "/CustomerContact", component: View.CustomerContact },

    { id: 12, group: "기준정보", level: 2, title: "프로젝트 관리정보", path: "/Territory", component: View.Territory },
    { id: 13, group: "기준정보", level: 2, title: "생산/물류업체 정보", path: "/Vendor", component: View.Vendor },
    { id: 17, group: "기준정보", level: 2, title: "매출/비용 단가 현황", path: "/SalesCost", component: View.SalesCost },
    { id: 91, group: "", title: "사용자", level: 2, path: "/user", component: View.User },
  ];

  export default SiteMap;