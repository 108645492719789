import { Dispatch } from "react";

const SET_DIFF = 'counter/SET_DIFF' as const;
const INCREASE = 'counter/INCREASE' as const;
const DECREASE = 'counter/DECREASE' as const;

/* �׼� �����Լ� ����� */
// �׼� �����Լ��� ����� export Ű���带 ����ؼ� �������ּ���.
export const setDiff = (diff: number) => ({ type: SET_DIFF, diff });
export const increase = () => ({ type: INCREASE });
export const decrease = () => ({ type: DECREASE });

export const increaseAsync = () => (dispatch: Dispatch<any>) => {
    setTimeout(() => dispatch(increase()), 3000);
};

export const decreaseAsync = () => (dispatch: Dispatch<any>) => {
    setTimeout(() => {
        dispatch(decrease());

    }, 3000);
};


/* �ʱ� ���� ���� */
const initialState = {
    number: 0,
    diff: 1
};

type Action = ReturnType<typeof increase>
    | ReturnType<typeof setDiff>
    | ReturnType<typeof decrease>;


/* ���༭ ���� */
// ���༭�� export default �� �������ּ���.
export default function counter(state = initialState, action: Action) {
    switch (action.type) {
        case SET_DIFF:
            return {
                ...state,
                diff: action.diff
            };
        case INCREASE:
            return {
                ...state,
                number: state.number + state.diff
            };
        case DECREASE:
            return {
                ...state,
                number: state.number - state.diff
            };
        default:
            return state;
    }
}