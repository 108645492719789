import * as React from "react";
import { BusyIndicator, BusyIndicatorContext, groupBy, useGetData, useLoginInfo } from "./DataGrid";
import { Accordion, Drawer, List, Toolbar } from "devextreme-react";
import SiteMap from "./views/SiteMap";
import classes from "./Layout.module.css";
import { useHistory, useLocation } from "react-router";
import { Item } from "devextreme-react/toolbar";

export default function Layout(props: { children?: React.ReactNode | any }) {
    const [busy, setBusy] = React.useState({ busy: false, msg: "" });
    const [show, setShow] = React.useState(true);
    let title = "";
    let subtitle = "";
    const history = useHistory();
    const location = useLocation();
    const login = useLoginInfo();

    var menu = SiteMap.find((m) => m.path.toLocaleLowerCase() === location.pathname.toLowerCase());
    if (menu !== undefined) {
        title = (menu.group ? "/" + menu.group : "") + "/" + menu.title;
        subtitle = menu.title;
    }

    document.title = subtitle + " - 매출판매관리";

    const renderLabel = () => {
        return <div className={classes.subtitle}>{subtitle}</div>;
    };

    const busyAction = (busy: boolean, msg: string) => {
        setBusy({ busy, msg });
    };
    //  ,

    return (
        <BusyIndicatorContext.Provider value={busyAction}>
            <div className={classes.appbar}>
                <Toolbar>
                    <Item
                        widget="dxButton"
                        location="before"
                        options={{
                            icon: "menu",
                            onClick: () => {
                                setShow(!show);
                            },
                        }}
                    />
                    <Item
                        widget="dxButton"
                        location="before"
                        options={{
                            icon: "home",
                            onClick: () => {
                                history.push("/");
                            },
                        }}
                    />

                    <Item location="center" locateInMenu="never" render={renderLabel}></Item>

                    <Item
                        widget="dxButton"
                        location="after"
                        visible={login.level == 2}
                        options={{
                            icon: "user",
                            text: "사용자 관리",
                            onClick: () => {
                                history.push("/user");
                            },
                        }}
                    />

                    <Item
                        widget="dxButton"
                        location="after"
                        options={{
                            icon: "file",
                            text: "탭 복제",
                            onClick: () => {
                                let obj = window.open(location.pathname, "_blank");
                                obj?.focus();
                            },
                        }}
                    />
                </Toolbar>
            </div>
            <Drawer
                opened={true}
                openedStateMode="shrink"
                position="left"
                revealMode="slide"
                component={Sidebar}
                closeOnOutsideClick={(evt): boolean => {
                    setShow(false);
                    return true;
                }}
                height="100%">
                <div className={classes.container}>{props.children}</div>
            </Drawer>

            <BusyIndicator busy={busy.busy} message={busy.msg} />
        </BusyIndicatorContext.Provider>
    );
}

function Sidebar() {
    const [menu, setMenu] = React.useState<any[]>([]);
    const history = useHistory();
    const location = useLocation();
    const login = useLoginInfo();

    React.useEffect(() => {
        const source = SiteMap.map((m) => m);
        var source2 = groupBy(
            source.filter((m) => m.group != "" && m.level <= login.level),
            (m) => m.group
        );

        var menus_raw = Array.from(source2).map((m) => ({
            key: m[0],
            items: m[1],
        }));
        setMenu(menus_raw);
    }, [location]);

    const GroupTemplate = (item: any) => {
        return <div className={classes.subtitle}>{item.key}</div>;
    };
    const ItemTempalte = (item: any) => {

        if (location.pathname.toLowerCase() == item.path.toLowerCase()) {
            return (
                <div
                    className={classes.item}
                    style={{
                        color: "red",
                        fontWeight : "bold"
                    }}
                    onClick={() => {
                        history.push(item.path);
                    }}>
                    {item.title} ◀
                </div>
            );
        }
        else {
            return (
                <div
                    className={classes.item}
                    style={{color: "#777777"}}
                    onClick={() => {
                        history.push(item.path);
                    }}>
                    {item.title}
                </div>
            );
        }


    };

    const onOptionChanged = ({ name, value }: { name?: string; value?: string }) => { };

    return (
        <>
            <div id="sidebar-zone" className={classes.sidebar}>
                <List
                    dataSource={menu}
                    height="100%"
                    grouped={true}
                    selectionMode="single"
                    onOptionChanged={(e) => onOptionChanged(e)}
                    collapsibleGroups={true}
                    groupRender={GroupTemplate}
                    itemRender={ItemTempalte}
                />
            </div>
        </>
    );
}

function CustomTitle(data: any) {
    return (
        <>
            <div className={classes.title}>{data.key}</div>
        </>
    );
}

function CustomItem(data: any) {
    const handlerClick = (e: React.MouseEvent, p: string) => {
        e.preventDefault();
        data.history.push(p);
    };

    return (
        <>
            {data.items.map(({ id, title, path, isHide }: { id: number; title: string; path: string; isHide?: boolean }) => (
                <div className={classes.item} key={id}>
                    <a href="#" onClick={(e) => handlerClick(e, path)}>
                        {title}
                    </a>
                </div>
            ))}
        </>
    );
}
