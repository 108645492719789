import { Button, Popup, TextArea } from "devextreme-react";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import * as React from "react";
import { DataGrid, GetDataSource, Column, Lookup, ValueFormat, useBusy, usePostData, useGetData } from "../DataGrid";

export function SalesCost() {
    const [show, setShow] = React.useState(false);
    const [refresh, setRefresh] = React.useState(new Date());
    const setBusy = useBusy();
    const post = usePostData();
    const get = useGetData();

    const costTypes = ["제품단가", "포장단가", "서열단가", "제품+포장단가", "투자비단가", "납품대행단가", "기간정산단가"];
    const vendors = React.useMemo(() => GetDataSource("CodeMaster", "Vendors"), [0]);

    const uploadAction = async (value: string) => {
        try {
            setBusy("D9파일 업로드 중입니다...");
            var res = await post("/api/SalesCost/Upload", { content: value });
            setShow(false);
            if (res.error) {
                notify(res.error, "error", 5000);
            }
        } catch { }
        setBusy();
    };

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "add",
                    hint: "파일 업로드",
                    onClick: () => {
                        setShow(true);
                    },
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: "제품/포장단가-결산(판) 생성",
                    onClick: () => {
                        setBusy("잠시만 기다려주십시오");
                        get('/api/SalesCost/Action1').then(re => {
                            setBusy();
                            if (re.error) {
                                notify(re.error, 'error', 5000);
                            }
                            else {
                                setRefresh(new Date());
                            }
                        });
                    },
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: "포장단가-검수단가(고) 생성",
                    onClick: () => {
                        setBusy("잠시만 기다려주십시오");
                        get('/api/SalesCost/Action2').then(re => {
                            setBusy();
                            if (re.error) {
                                notify(re.error, 'error', 5000);
                            }
                            else {
                                setRefresh(new Date());
                            }
                        });
                    },
                },
            }
        );
    };

    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource("SalesCost")} allowAdding={false} showGroupPanel={false} allowDeleting={true} onToolbarPreparing={onToolbarPreparing}>
                <Column dataField="Id" caption="Id"></Column>
                <Column dataField="CustomerCd" caption="발주사코드"></Column>
                <Column dataField="CusPartNo" caption="고객 품번" />
                <Column dataField="Category1" caption="단가 유형" width={150} />
                <Column dataField="Category2" caption="단가 종류">
                    <Lookup dataSource={costTypes} />
                </Column>
                <Column dataField="Vendor1" caption="포장/서열업체" width={150}>
                    <Lookup dataSource={vendors} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="Created" caption="접수일" width={150} dataType="date" format="yyyy-MM-dd" />
                <Column dataField="BeginDate" caption="시작일" dataType="date" format="yyyy-MM-dd" />
                <Column dataField="ExpiredDate" caption="종료일" dataType="date" format="yyyy-MM-dd" />
                <Column dataField="Curr" caption="화폐 권종" />
                <Column dataField="Cost" caption="단가" format="###,###">
                    <ValueFormat precision={2} type="decimal" />
                </Column>
                <Column dataField="Evidence" caption="근거" width={300} />
                <Column dataField="Comment" caption="비고" allowFiltering={true} width={300}></Column>
                <Summary>
                    <TotalItem column="CustomerCd" displayFormat="{0}" summaryType="count" valueFormat="###,##0" />
                </Summary>
            </DataGrid>

            <UploadForm
                show={show}
                onHiding={() => {
                    setShow(false);
                }}
                uploadAction={uploadAction}
            />
        </div>
    );
}

type UploadFormPropType = {
    uploadAction?: (value: string) => void;
    onHiding: () => void;
    show: boolean;
};

function UploadForm(props: UploadFormPropType) {
    const [value, setValue] = React.useState("");

    return (
        <Popup visible={props.show} onHiding={props.onHiding} dragEnabled={false} closeOnOutsideClick={true} showTitle={true} title="매출 단가 업로드" width={700} height={450}>
            <div>제목줄까지 포함해서 붙여넣으십시오.</div>

            <TextArea
                height={240}
                onValueChanged={(e) => setValue(e.value)}
                style={{ marginTop: "0.5rem" }}
                value={value}
                placeholder="발주사코드/고객품번/단가종류/단가유형/포장서열업체/시작일/종료일/화폐권종/단가/근거/접수일/비고"></TextArea>

            <div>
                <Button
                    text="업로드"
                    style={{ marginTop: "16px", marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => {
                        if (props.uploadAction) {
                            props.uploadAction(value);
                            setValue("");
                        }
                    }}
                />
            </div>
        </Popup>
    );
}
