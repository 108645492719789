import * as React from "react";
import classes from "./ErrorPages.module.css";

export function Error401() {
  return (
    <div className={classes.container}>
      <div className={classes.error} data-text="401">
        401
      </div>
      <p className={classes.text1}>Unauthorized. Access denied.</p>
      <p className={classes.text2}>
        권한이 없습니다. <br />
        <br /> 관리자(재경팀, 박상선 책임)에게 권한을 요청하시기 바랍니다.
      </p>

      <a className={classes.link} href="/">
        &larr; 재시도 하기
      </a>
    </div>
  );
}

//Forrbiden
export function Error403() {
  return (
    <div className={classes.container}>
      <div className={classes.error} data-text="403">
        403
      </div>
      <p className={classes.text1}>Unauthorized. Access denied.</p>
      <p className={classes.text2}>접근이 금지되었습니다. 관리자에게 문의하십시오....</p>
      <a className={classes.link} href="http://iportal.ihl.co.kr">
        &larr; 홈으로 이동하기
      </a>
    </div>
  );
}
