import * as React from "react";
import { DataGrid, Column, GetDataSource, Lookup } from "../DataGrid";

export function Customer() {
    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource("customer")} showGroupPanel={false} showHeaderFilter={true}>
                <Column dataField="Id" caption="ID" allowEditing={false}></Column>
                <Column dataField="Name" caption="발주사" width={240}></Column>
                <Column dataField="SapCode" caption="발주사코드" width={110}></Column>

                <Column dataField="BuyerName" caption="판매사" width={240}></Column>
                <Column dataField="BuyerCode" caption="판매사코드" width={110}></Column>

                <Column dataField="DemandId" caption="수요(SAP)" width={110}>
                    <Lookup dataSource={GetDataSource("CodeMaster", "Demands")} displayExpr="Name" valueExpr="Id" />
                </Column>
                <Column dataField="IsCkd" caption="CKD여부"></Column>
                <Column dataField="PlantCode" caption="공장코드" width={110}></Column>
                <Column dataField="Remark" caption="비고" allowFiltering={false} width={300}></Column>
                <Column></Column>
            </DataGrid>
        </div>
    );
}
