import * as React from 'react';

const initState = {
    count: 0,
    sidebar_open: false,
    busy: false,
}

type StateType = typeof initState;
type ActionType = { type: 'INCREMENT' } | { type: 'DECREMENT' } | { type: 'SIDEBAR', open: boolean } | { type: 'BUSY', busy: boolean };
type DisaptchType = (action: ActionType) => void;

const AppContext = React.createContext<StateType>(initState);
const AppDispatchContext = React.createContext<DisaptchType | undefined>(undefined);

function reducer(state: StateType, action: ActionType) {

    if (action.type === 'INCREMENT') {
        return { ...state, count: state.count + 1 };
    }
    else if (action.type === "DECREMENT") {
        return { ...state, count: state.count - 1 };
    }
    else if (action.type === "SIDEBAR") {
        return { ...state, sidebar_open: action.open };
    }
    else if (action.type === "BUSY") {
        return { ...state, busy: action.busy };
    }
    return state;
}

export function AppContextProvider({ children }: { children?: React.ReactNode }) {

    const [state, dispatch] = React.useReducer(reducer, initState);

    return (
        <>
            <AppContext.Provider value={state}>
                <AppDispatchContext.Provider value={dispatch}>
                    {children}
                </AppDispatchContext.Provider>
            </AppContext.Provider>
        </>
    );
}


export function useAppState() {

    const obj = React.useContext(AppContext);
    if (!obj) throw new Error('Cannot find AppContextProvider');
    return obj;
}

export function useAppDispatch() {
    const dispatch = React.useContext(AppDispatchContext);
    if (!dispatch) throw new Error('Cannot find AppContextProvider');
    return {
        setBusy: (busy?: boolean) => {
            if (!busy)
                dispatch({ type: "BUSY", busy: false });
            else
                dispatch({ type: "BUSY", busy: busy });
        },
        openSidebar: () => {
            dispatch({ type: "SIDEBAR", open: true });
        },
        closeSidebar: () => {
            dispatch({ type: "SIDEBAR", open: false });
        }
    };

}

