import * as React from "react";
import { DataGrid, Column, GetDataSource } from "../DataGrid";

export function Territory() {
  return (
    <div className="layoutRoot">
      <DataGrid source={GetDataSource("Territory")} showGroupPanel={false}>
        <Column dataField="Customer" caption="완성차"></Column>
        <Column dataField="Model3" caption="대표차종"></Column>
        <Column dataField="Args1" caption="공급수요"></Column>
        <Column dataField="Name" caption="세부프로젝트"></Column>
        <Column dataField="Model1" caption="보고차종"></Column>
        <Column dataField="Model4" caption="프로젝트(SAP)"></Column>
        <Column dataField="Model5" caption="차종코드(SAP)"></Column>
        <Column dataField="Model2" caption="모델코드(SAP)"></Column>
        <Column dataField="Args2" caption="모델코드(관리)"></Column>
        <Column dataField="Country" caption="국가"></Column>
        <Column dataField="Plant" caption="생산공장"></Column>
        <Column dataField="Args3" caption="양산일자"></Column>
        <Column dataField="Args4" caption="단산일자"></Column>

        {/* 
        <Column dataField="YearInGoals" caption="제품매출목표"></Column>
        <Column dataField="YearOutGoals" caption="상품매출목표"></Column>
        <Column dataField="TotalGoals" caption="매출목표액"></Column>

        <Column dataField="LastYearInGoals" caption="제품매출목표(작년)"></Column>
        <Column dataField="LastYearOutGoals" caption="상품매출목표(작년)"></Column>
        <Column dataField="LastYearTotalGoals" caption="매출목표액(작년)"></Column> */}

        <Column dataField="Remark" caption="비고" allowFiltering={false}></Column>
        {/* <Column /> */}
      </DataGrid>
    </div>
  );
}
