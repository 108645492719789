import { Button, TextArea } from "devextreme-react";
import Popup from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import * as React from "react";

import { DataGrid, GetDataSource, Column, StringLengthRule, Lookup, usePostData, BusyIndicator, Summary, TotalItem, useGetData, useBusy } from "../DataGrid";

export function CustomerPart() {
    const [show, setShow] = React.useState(false);
    const [content, setContent] = React.useState("");
    const post = usePostData();
    const $get = useGetData();
    const busy = useBusy();

    const createNewFromTaxInvoice = async () => {

        busy("신규 검수품 생성중...");
        var res = await $get("/api/CustomerPart/CreateNewFromTaxInvoice");
        if (res.error) {
            notify(res.error,"error",2000);
        }
        busy();
    };

    const updateAllInspecMonth = async () => {
        busy("최종 검수월 확인중...");
        var res = await $get("/api/CustomerPart/UpdateAllInspecMonth");
        if (res.error) {
            notify(res.error, "error", 2000);
        }
        busy();
    };

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before", widget: "dxButton", options: {
                    text : "신규 검수품 생성",
                    onClick: createNewFromTaxInvoice
                }
            },
            {
                location: "before", widget: "dxButton", options: {
                    text: "검수월 생성",
                    onClick: updateAllInspecMonth
                }
            },
            {
            location: "after",
            widget: "dxButton",
            options: {
                text: "일괄 생성",
                onClick: () => {
                    setShow(true);
                },
            },
        });
    };

    const handlerUpload = async () => {
        setShow(false);
        var res = await post("/api/CustomerPart/Upload", { data: content });
        if (res.error) {
            notify(res.error, "error", 2000);
            setShow(true);
        } else {
            setContent("");
        }
    };

    const plants = React.useMemo(() => GetDataSource("CodeMaster", "Plants"), [0]);
    const territoies = React.useMemo(() => GetDataSource("CodeMaster", "Territories"), [0]);
    const cutomers = React.useMemo(() => GetDataSource("CodeMaster", "Customers"), [0]);
    const demands = React.useMemo(() => GetDataSource("CodeMaster", "Demands"), [0]);
    const vendors = React.useMemo(() => GetDataSource("CodeMaster", "Vendors"), [0]);

    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource("CustomerPart")} showGroupPanel={false} onToolbarPreparing={onToolbarPreparing}>
                <Column dataField="Id" caption="Id" />
                <Column dataField="InspecMonth" caption="최종 검수월"></Column>
                <Column dataField="IsDeleted" caption="관리종료"></Column>
                <Column dataField="Flag1" caption="물류적용"></Column>


                <Column dataField="PlantId" caption="플랜트" width={220}>
                    <Lookup dataSource={plants} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="Customer.BuyerName" caption="판매사" width={180}></Column>
                <Column dataField="Customer.SapCode" caption="판매사코드" width={100}></Column>

                <Column dataField="CustomerId" caption="발주사" width={240}>
                    <Lookup dataSource={cutomers} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="DemandId" caption="수요">
                    <Lookup dataSource={demands} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="Demand.Category" caption="보고수요"></Column>

                <Column dataField="SalesType" caption="결산유형" />

                <Column dataField="Territory.Model3" caption="대표차종" width={150}></Column>

                <Column dataField="TerritoryId" caption="세부 프로젝트" width={150}>
                    <Lookup dataSource={territoies} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="Territory.Model1" caption="보고차종" width={150}></Column>
                <Column dataField="Territory.Model4" caption="차종(SAP)" width={100}></Column>

                <Column dataField="PartNo" caption="고객품번" width={180}></Column>

                <Column dataField="PartNo2" caption="IHL품번" width={180}></Column>
                <Column dataField="PartName" caption="품명"></Column>


                <Column dataField="Item" caption="품목군" />

                <Column dataField="Light" caption="광원" />

                <Column dataField="Territory.Customer" caption="완성차고객" width={100}></Column>
                <Column dataField="Territory.Plant" caption="생산공장" width={100}></Column>
                <Column dataField="Territory.Country" caption="국가" width={100}></Column>

                <Column dataField="VendorId1" caption="생산업체" width={180}>
                    <Lookup dataSource={vendors} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="VendorId2" caption="포장업체" width={180}>
                    <Lookup dataSource={vendors} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="VendorId3" caption="서열업체" width={180}>
                    <Lookup dataSource={vendors} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="PackCostType" caption="포장비 매출 유형" width={180}></Column>
                <Column dataField="JITCostType" caption="서열비 매출 유형" width={180}></Column>
                <Column dataField="CostRatio" caption="관리비지급율" format="#0.##" allowFiltering={false}></Column>

                <Column dataField="Remark" caption="비고" allowFiltering={false}></Column>


                <Column dataField="Created" caption="생성일" allowEditing={false} allowFiltering={false}></Column>

                <Column />
                <Summary>
                    <TotalItem column="PlantId" displayFormat="{0}" summaryType="count" valueFormat="###,##0" />
                </Summary>
            </DataGrid>

            <Popup
                visible={show}
                dragEnabled={false}
                onHiding={() => {
                    setShow(false);
                }}
                closeOnOutsideClick={true}
                showTitle={true}
                title="품목정보 생성"
                width={700}
                height="26rem">
                <div>엑셀에서 붙여넣으십시오. 이미 있다면 변경하지 않습니다. </div>
                <div style={{ color: "Red", fontSize: "0.8rem" }}>프로젝트 &amp; 발주사명&amp; 고객품번 으로 중복 등록 불가</div>
                <div style={{ marginTop: "0.5rem" }}>
                    <TextArea
                        height="14rem"
                        value={content}
                        placeholder="공장(코드) / 세부프로젝트 / 수요 / 발주사명 / 고객품번 / IHL품번 /제품군/광원/생산업체(업체명)/포장업체/서열업체/포장비매출유형/서열비매출유형/관리비지급율/비고"
                        onValueChanged={(e: any) => {
                            setContent(e.value);
                        }}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
                    <Button text="업로드" stylingMode="contained" width={130} onClick={handlerUpload} />
                </div>
            </Popup>
        </div>
    );
}
