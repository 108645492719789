import * as React from "react";

import {  Column, IDataGridOptions } from "devextreme-react/data-grid";
import { GetDataSource,DataGrid } from "../DataGrid";


export function Demand() {

    
    return (
        <div className="layoutRoot" >
            <DataGrid source={GetDataSource("Demand")} allowAdding={true} allowEditing={true} allowDeleting={true}  >
                <Column dataField="Id" caption="ID" allowEditing={false}></Column>
                <Column dataField="Name" caption="이름" width={240}></Column>
                <Column dataField="Category" caption="보고수요" width={110}></Column>
                <Column dataField="SapCode" caption="추정코드(SAP)" width={110}></Column>
                <Column></Column>
            </DataGrid>
        </div>
    );
}
