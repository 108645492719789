import * as React from "react";
import { GetDataSource, Column, DataGrid } from "../DataGrid";

export function TestQuery() {

    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource("TestQuery")} allowEditing={false}
                allowAdding={false} allowDeleting={false} showGroupPanel={false} allowExport={false}>
            </DataGrid >
        </div>
    );

}