import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";

import * as React from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import { AppContextProvider } from "./AppContext";
import { useGetData, useLoginInfo } from "./components/DataGrid";
import SiteMap from "./components/views/SiteMap";
import { Error401, Error403 } from "./components/views/ErrorPages";
import { TestQuery } from "./components/views/TestQuery";

export default () => {
    const get = useGetData();
    const login = useLoginInfo();

    const token = sessionStorage.getItem("token");
    if (!token) {
        return <Error403 />;
    }

    if (login.level == -1) {
        return <Error401 />;
    } else {
        return (
            <AppContextProvider>
                <Layout>
                    {/*<Route exact path="/sso/login" component={SSOLogin} />*/}
                    <Route exact path="/401" component={Error401} />
                    <Route exact path="/403" component={Error403} />
                    <Route exact path="/TestQuery" component={TestQuery} />

                    {SiteMap.map((m) => (
                        <Route exact path={m.path} component={m.component} key={m.id} />
                    ))}
                </Layout>
            </AppContextProvider>
        );
    }
};
