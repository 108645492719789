import { Lookup } from "devextreme-react/data-grid";
import * as React from "react";
import { DataGrid, Column, GetDataSource, TotalItem, Summary, formatMonth } from "../DataGrid";

export function SapUploadFile() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [date1, setDate1] = React.useState(formatMonth(new Date(), -1));

    const cutomers = React.useMemo(() => GetDataSource("CodeMaster", "Customers"), [0]);

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate(e.value),
                },
            },
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date1,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate1(e.value),
                },
            }
        );
    };


    return (
        <div className="layoutRoot">
            <DataGrid
                source={GetDataSource("SapUploadFile", "Get/" + date + "-" + date1)}
                showHeaderFilter={true}
                showGroupPanel={false}
                allowEditing={true}
                allowExport={true}
                onToolbarPreparing={onToolbarPreparing}
                allowDeleting={true}
                allowAdding={false}>

                <Column dataField="Customer" caption="판매처이름" width={220} allowExporting={false}>
                    <Lookup dataSource={cutomers} displayExpr="Name" valueExpr="SapCode" />
                </Column>

                <Column dataField="Amount" caption="금액" allowExporting={false} format="###,##0" width={200}></Column>
                <Column dataField="DocDate" caption="매출일자" allowExporting={false}></Column>
                <Column caption="비고" dataField="Tag1"></Column>

                <Column dataField="Plant" caption="플랜트" width={50}></Column>
                <Column dataField="Code1" caption="오더유형" width={100}></Column>
                <Column dataField="Distribu" caption="유통경로" width={50}></Column>
                <Column dataField="Customer" caption="판매처"></Column>

                <Column caption="PO번호" dataField="Tag2"></Column>

                <Column dataField="Code2" caption="오더사유" width={50}></Column>
                <Column dataField="PartNo" caption="고객자재번호"></Column>
                <Column dataField="Demand" caption="수요구분" width={50}></Column>
                <Column dataField="Currency" caption="적용통화" width={50}></Column>
                <Column dataField="ExchangeRate" caption="적용환율" width={50}></Column>
                <Column dataField="Qty" caption="검수수량" width={150}></Column>
                <Column dataField="UnitPrice" caption="제품검수단가" format="###,###.00" width={150}></Column>
                <Column caption="단위개수" width={30}></Column>
                <Column caption="포장단가" width={30}></Column>
                <Column caption="포장단위개수" width={30}></Column>

                <Column dataField="DocMonth2" caption="대체매출일자"></Column>
                <Column dataField="InvoiceNo" caption="세금계산서승인번호" width={220}></Column>
                <Column></Column>
                <Summary>
                    <TotalItem column="PartNo" displayFormat="항목수: {0}" summaryType="count" />
                    <TotalItem column="Qty" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                    <TotalItem column="Amount" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                </Summary>
            </DataGrid>
        </div>
    );
}
