/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import * as React from "react";
import { DataGrid, Column, GetDataSource, useGetData } from "../../DataGrid";
import HomeDialog from "./Home_Dialog";
import { ProgressBar } from "devextreme-react/progress-bar";

export default ({ month }: {month : string}) => {
  const [data, setData] = React.useState([
    { Actual: 0, Target: 0, Rate: 0 },
    { Actual: 0, Target: 0, Rate: 0 },
    { Actual: 0, Target: 0, Rate: 0 },
  ]);

  const get = useGetData();

  React.useEffect(() => {
    get("/api/home/GetDashBoard/" + month).then((res) => {
      setData(res);
    });
  }, [month]);

  return (
    <div
      css={css`
        & {
          position: relative;
          box-sizing: border-box;
          display: flex;
          width: 100%;
          height: 100px;
          align-items: stretch;
          justify-content: space-around;
        }
      `}>
      <Title title="Month" data={data[0]} />
      <Title title="Quarter" data={data[1]} />
      <Title title="Year" data={data[2]} />
    </div>
  );
};

const classes = css`
  & {
    position: relative;
    box-sizing: border-box;
    min-width: 280px;
    box-shadow: 0px 10px 15px #999999;
    padding: 1rem;
    border-radius: 10px;
    .title {
      font-weight: bold;
      right: 1rem;
      bottom: 1rem;
      font-size: 1.8rem;
      opacity: 0.5;
      color: #999999;
      display: block;
      padding: 0 0 6px 0;
      line-height: 1.2rem;
      position: absolute;
    }
    .text1 {
      display: flex;
      color: #999999;
      padding: 0 0 6px 0;
      font-size: 0.92rem;
      justify-content: space-between;
      small {
        font-size: 0.45rem !important;
        margin-right: 0.5rem;
        color: #999999;
      }
    }
  }

  &:nth-of-type(1) {
    border-left: solid 5px #20bf6b;
    .dx-progressbar-range {
      background-color: #20bf6b;
      border: none;
    }
  }

  &:nth-of-type(2) {
    border-left: solid 5px #fed330;
    .dx-progressbar-range {
      background-color: #fed330;
      border: none;
    }
  }
  &:nth-of-type(3) {
    border-left: solid 5px #eb3b5a;
    .dx-progressbar-range {
      background-color: #eb3b5a;
      border: none;
    }
  }
`;

function Title(props: { title: string; data?: { Actual: number; Rate: number; Target: number } | any }) {
  const data = props.data;
  return (
    <div css={classes}>
      <span className="glass"></span>
      <span className="title">{props.title}</span>
      <span className="text1">
        {props.data?.Actual} / {props.data?.Target} <small>단위:억원</small>
      </span>
      <ProgressBar width="90%" min={0} max={props.data?.Target} statusFormat={(value: number) => Math.round((data.Actual / data.Target) * 100) + " %"} value={props.data?.Actual} />
    </div>
  );
}
