/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Popup } from "devextreme-react";
import * as React from "react";
import { DataGrid, Column, GetDataSource } from "../../DataGrid";

export default function HomeDialog(props: { show: boolean; onHiding: () => void }) {
  return (
    <Popup visible={props.show} dragEnabled={false} onHiding={props.onHiding} closeOnOutsideClick={true} showTitle={true} title="매출 계획 편집" width={660} height={650}>
      <DataGrid source={GetDataSource("SalesTotal")} allowAdding={false} allowDeleting={false} allowExport={false}>
        <Column dataField="Year" caption="연도" allowEditing={false} width={80}></Column>
        <Column dataField="Quarter" caption="분기" allowEditing={false} width={80}></Column>
        <Column dataField="Month" caption="월" allowEditing={false} width={80}></Column>
        <Column dataField="Target" caption="계획(억)" format="###,##0.##" allowEditing={true}></Column>
        <Column dataField="Actual" caption="실적(억)" format="###,##0.##" allowEditing={true}></Column>
        <Column />
      </DataGrid>
    </Popup>
  );
}
