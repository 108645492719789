import * as React from 'react';
import { GetDataSource, Column,DataGrid } from '../DataGrid';


export function CustomerPartMap() {

    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource('CustomerPartMap')} allowEditing={false} allowAdding={false} allowDeleting={false} showGroupPanel={false} allowExport={true}>
                <Column dataField="Plant" caption="플랜트" allowEditing={false}></Column>
                <Column dataField="Customer" caption="발주사코드" width={100} allowEditing={false}></Column>
                <Column dataField="PartNo" caption="고객품번" width={180} allowEditing={false} allowHeaderFiltering={false}></Column>
                <Column dataField="PartNo2" caption="IHL품번" width={180} allowEditing={false} allowHeaderFiltering={false}></Column>

                <Column dataField="StartDate" caption="적용시작일" width={100} allowEditing={false} dataType="date" allowHeaderFiltering={false}></Column>
                <Column dataField="EndDate" caption="적용종료일" width={100} allowEditing={false} dataType="date" allowHeaderFiltering={false}></Column>
                <Column dataField="IsDeleted" caption="삭제처리" width={100} allowEditing={false}></Column>
                <Column />
            </DataGrid>
        </div >
    );
}




