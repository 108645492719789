/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import * as React from "react";
import { Button, DateBox } from "devextreme-react";
import { AreaChart } from "./AreaChart";
import TitleBox from "./TitleBox";
import HomeDialog from "./Home_Dialog";
import Comment from "./Comment";

const classes = css`
  overflow-y: auto;
  height: calc(100vh - 78px);

  .container {
    max-width: 1000px;
    height: calc(100vh + 220px);
  }

  .header {
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
    .h3 {
      font-weight: normal;
      font-size: 1.2rem;
      color: #2980b9;
      small {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }
  }

  .header > * {
    margin-left : 12px;
  }

  .body {
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
    /*border-bottom: solid 3px #efefef;
     border-radius: 8px; */
    width: 100%;
  }
`;

function format(date: Date) {
    return date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0');
}

export function HomeView() {
    const [showEdit, setShowEdit] = React.useState(false);
    const [date, setDate] = React.useState(new Date())

    React.useEffect(() => {
        if (date.getDate() < 9) {
            setDate(new Date(date.getFullYear(), date.getMonth() - 1, 0));
        }
        else {
            setDate(new Date(date.getFullYear(), date.getMonth(), 0));
        }
    }, [0]);

    console.log(format(date));

    return (
        <div css={classes}>
            <div className="container">
                <HomeDialog
                    show={showEdit}
                    onHiding={() => {
                        setShowEdit(false);
                        window.location.reload();
                    }}
                />
                <section className="header">
                    <DateBox width={130} acceptCustomValue={false} value={date} onValueChanged={(e: any) => { setDate(e.value); }} displayFormat="yyyy.MM" />
                    <Button type="normal" onClick={() => setShowEdit(true)} hint="계획 편집" icon="edit"></Button>
                </section>

                <section className="body">
                    <TitleBox month={format(date)} />
                </section>

                <section className="body">
                    <Comment month={format(date)}></Comment>
                </section>
                <section className="body">
                    <AreaChart month={format(date)}></AreaChart>
                </section>
            </div>
        </div>
    );
}
