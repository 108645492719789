import * as React from "react";
import { GetDataSource, Column, DataGrid } from "../DataGrid";

export function Part() {
  return (
    <div className="layoutRoot">
      <DataGrid source={GetDataSource("Part")} allowEditing={false} allowAdding={false} allowDeleting={false} showGroupPanel={false} allowExport={true}>
        <Column dataField="Plant" caption="플랜트" allowEditing={false} width={80}></Column>
        <Column dataField="State" caption="상태" allowEditing={false} width={80}></Column>
        <Column dataField="BESKZ" caption="조달유형" allowEditing={false} width={80}></Column>
        <Column dataField="Model" caption="차종(SAP)" allowEditing={false} width={80}></Column>
        <Column dataField="ModelName" caption="차종명" allowEditing={false} width={80}></Column>
        <Column dataField="Proj" caption="대표프로젝트*"></Column>
        <Column dataField="RegionSpec" caption="지역사양*" width={80}></Column>
        <Column dataField="PartNo" caption="IHL품번" width={180} allowEditing={false}></Column>
        <Column dataField="PartName" caption="품명" width={280} allowHeaderFiltering={false}></Column>
        <Column dataField="SPARTName" caption="제품군명" allowEditing={false}></Column>
        <Column dataField="SPART" caption="제품군" allowEditing={false} width={80}></Column>
        <Column dataField="LightType" caption="광원*" width={80}></Column>
        <Column dataField="Useage" caption="U/S*" width={70}></Column>
        <Column dataField="Remark" caption="비고*" allowFiltering={false} width={280}></Column>
        <Column dataField="MTART" caption="자재유형" allowEditing={false}></Column>
        <Column dataField="MATKL" caption="자재그룹" allowEditing={false} width={80}></Column>
        <Column dataField="Created" caption="생성일" width={180} dataType="date" allowHeaderFiltering={false} allowEditing={false}></Column>

        {/*
               
                <Column dataField="Modified" caption="변경일" width={180} dataType="date" allowHeaderFiltering={false}  allowEditing={false}></Column>
                */}

        <Column />
      </DataGrid>
    </div>
  );
}
