import { Summary, TotalItem } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import * as React from "react";
import { DataGrid, Column, GetDataSource, Lookup, formatMonth, useGetData, useBusy, usePostData } from "../DataGrid";

export default function SalesData() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [date1, setDate1] = React.useState(formatMonth(new Date(), -1));

    const post = usePostData();
    const setBusy = useBusy();

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate(e.value),
                },
            },
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date1,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate1(e.value),
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: "전체 생성",
                    icon: "refresh",
                    stylingMode: "contained",
                    onClick: () => {
                        setBusy("데이터를 집계하고 있습니다.\n잠시만 기다려 주십시오.");
                        post("/api/SalesData/Create", { month: date, force: true }).then((res) => {
                            setBusy();
                            if (res.error) {
                                notify(res.error, "error", 3000);
                            }
                        });
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    text: "KPI 반영",
                    icon: "chart",
                    stylingMode: "contained",
                    onClick: () => {
                        setBusy("KPI에 반영하기 위해 집계 데이터를 생성하고 있습니다.\n잠시만 기다려 주십시오.");
                        post("/api/SalesData/Summary", { month: date, force: true }).then((res) => {
                            setBusy();
                            if (res.error) {
                                notify(res.error, "error", 3000);
                            }
                        });
                    },
                },
            }

            // {
            //   location: "after",
            //   widget: "dxButton",
            //   options: {
            //     hint: "항목 분리",
            //     icon: "share",
            //     stylingMode: "contained",
            //     onClick: () => {},
            //   },
            // }
        );
    };

    return (
        <div className="layoutRoot">
            <DataGrid source={GetDataSource("SalesData", "Get/" + date + "-" + date1)} allowAdding={false} showGroupPanel={true} showHeaderFilter={true} onToolbarPreparing={onToolbarPreparing}>
                <Column dataField="Id" caption="ID" allowEditing={false}></Column>

                <Column dataField="Customer.BuyerName" caption="판매고객사" allowEditing={false}></Column>

                <Column dataField="DocMonth" caption="결산년월" allowEditing={false}></Column>

                <Column dataField="CustomerPart.PlantId" caption="플랜트" allowEditing={false}></Column>

                <Column dataField="DemandTxt" caption="수요" allowEditing={false} width={60}></Column>
                <Column dataField="CustomerTxt" caption="발주사" allowEditing={false} width={220}></Column>
                <Column dataField="SalesType" caption="매출유형" allowEditing={false}></Column>

                <Column dataField="Proj" caption="프로젝트" allowEditing={false}></Column>
                <Column dataField="PartNo" caption="고객품번" allowEditing={false} width={150}></Column>
                <Column dataField="CustomerPart.PartNo2" caption="IHL품번" allowEditing={false}></Column>
                <Column dataField="UnitPrice" caption="매출검수단가" allowEditing={false} format="###,##0.##"></Column>
                <Column dataField="Amount" caption="매출검수금액" allowEditing={false} format="###,##0" width={150}></Column>
                <Column dataField="Qty" caption="수량" allowEditing={true} format="###,##0" width={150}></Column>

                <Column dataField="CustomerPart.Territory.Customer" caption="완성품고객사" allowEditing={false}></Column>
                <Column dataField="CustomerPart.Territory.Model1" caption="보고차종" allowEditing={false}></Column>

                <Column dataField="CustomerPart.Item" caption="품목군" allowEditing={false}></Column>

                <Column dataField="CustomerPart.Light" caption="광원" allowEditing={false}></Column>

                <Column dataField="ParentId" caption="연결ID" allowEditing={false}></Column>
                <Column dataField="Remark" caption="세부관리사항" allowFiltering={false} allowEditing={true} width={200}></Column>

                <Column  caption="고객사 단가" allowFiltering={false} allowEditing={true} width={200}></Column>
                <Column  caption="고객사 매출금액" allowFiltering={false} allowEditing={true} width={200}></Column>
                <Column  caption="효과금액" allowFiltering={false} allowEditing={true} width={200}></Column>


                <Column dataField="Created" caption="생성일" allowEditing={false}></Column>

                <Summary>
                    <TotalItem column="Amount" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                    <TotalItem column="Qty" displayFormat="{0}" summaryType="sum" valueFormat="###,##0" />
                </Summary>
            </DataGrid>
        </div>
    );
}
