import * as React from "react";
import {
    Column as DevColumn,
    DataGrid as DevDataGrid,
    Lookup as DevLookup,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Scrolling,
    Editing,
    Grouping,
    StringLengthRule,
    Paging,
    Pager,
    Export,
} from "devextreme-react/data-grid";
import { LoadPanel as DevLoadPanel } from "devextreme-react/load-panel";
import { createStore } from "devextreme-aspnet-data-nojquery";
import ExcelJS from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import FileSaver from "file-saver";
import { dxDataGridRowObject } from "devextreme/ui/data_grid";
export { Column, Lookup, ValueFormat, StringLengthRule, TotalItem, Summary, MasterDetail } from "devextreme-react/data-grid";

interface ColumnPropType {
    dataField: string;
    caption?: string;
    width?: any;
    children?: React.ReactNode;
    allowFiltering?: boolean;
}

interface StringLengthColumnPropType extends ColumnPropType {
    max: number;
    min: number;
    message: string;
}

interface LookupColumnPropType extends ColumnPropType {
    source?: any;
    displayExpr?: string;
    valueExpr?: string;
}

export function withColumn(props: ColumnPropType) {
    props.width = props.width || "100";
    return new DevColumn({ ...props }).render();
}

export function StringLengthColumn(props: StringLengthColumnPropType) {
    const template = <StringLengthRule max={props.max} min={props.min} message={`${props.max}자 이내로 입력하십시오.`} />;
    return withColumn({ ...props, children: template });
}

export function LookupColumn(props: LookupColumnPropType) {
    const template = <DevLookup dataSource={props.source} displayExpr={props.displayExpr} valueExpr={props.valueExpr} />;
    return withColumn({ ...props, children: template });
}

export function GetDataSource(url: string, path: string | undefined = undefined): any {
    if (path === undefined) {
        path = "Get";
    }

    const token = sessionStorage.getItem("token");

    return createStore({
        key: "Id",
        loadUrl: `/api/${url}/${path}`,
        insertUrl: `/api/${url}/Insert`,
        updateUrl: `/api/${url}/Update`,
        deleteUrl: `/api/${url}/Delete`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: true };
            ajaxOptions.headers = {
                Authorization: "Bearer " + token,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            };
        },
    });
}

interface DataGridPropType {
    source: any[];
    children?: React.ReactNode | React.ReactChild | DevColumn[];
    showGroupPanel: boolean;
    showHeaderFilter: boolean;
    allowEditing: boolean;
    allowAdding: boolean;
    allowDeleting: boolean;
    keyExpr?: string;
    allowExport: boolean;
    width?: string;
    height?: string;
    onToolbarPreparing?: (e: any) => void;
    onSelectionChanged?: (e: DataGridSelectRowEvent | any) => void;
    onFocusedRowChanged?: (e: DataGridFocusedRowEvent | any) => void;
}

type LoginInfoType = {
    isSigned: boolean;
    token?: string | null;
    userName?: string | null;
    role?: string | null;
    displayName?: string | null;
    depts?: string | null;
    level: number;
};

export function useLoginInfo(): LoginInfoType {
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    if (!token) {
        return {
            isSigned: false,
            level: -1,
        };
    } else {
        let level = -1;
        if (role == "방문자") level = 0;
        if (role == "실무자") level = 1;
        if (role == "관리자") level = 2;

        return {
            isSigned: true,
            token: token,
            userName: sessionStorage.getItem("userName"),
            role: role,
            displayName: sessionStorage.getItem("displayName"),
            depts: sessionStorage.getItem("detps"),
            level: level,
        };
    }
}

export type DataGridSelectRowEvent = {
    selectedRowKeys: any[];
    selectedRowsData: any[];
};

export type DataGridFocusedRowEvent = {
    row: dxDataGridRowObject;
    model: any;
};

export function groupBy<T>(list: T[], keyGetter: (arg: T) => any) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

DataGrid.defaultProps = {
    showGroupPanel: false,
    showHeaderFilter: true,
    allowEditing: true,
    allowAdding: true,
    keyExpr: "Id",
    allowDeleting: true,
    allowExport: true,
    height: "100%",
    width: "100%",
};

export function formatDate(date: Date = new Date(), day_val: number = 0) {
    date.setDate(date.getDay() + day_val);
    let str = date.getFullYear() + "";
    let month = date.getMonth() + 1;
    let day = date.getDay();

    str += month < 10 ? "0" + month : month;
    str += day < 10 ? "0" + day : day;
    return str;
}

export function formatMonth(date: Date, month_val: number = 0) {
    date.setMonth(date.getMonth() + month_val);
    let str = date.getFullYear() + "";
    let month = date.getMonth() + 1;
    str += month < 10 ? "0" + month : month;
    return str;
}

export function BusyIndicator({ busy, message }: { busy: boolean; message: string }) {
    if (message === "") {
        message = "처리중입니다. 잠시만 기다려주세요.";
    }
    return (
        <DevLoadPanel
            width={320}
            height={200}
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: "#root" }}
            message={message}
            visible={busy}
            showIndicator={true}
            shading={true}
            showPane={true}
        />
    );
}
BusyIndicator.defaultProps = { busy: false, message: "" };

export function DataGrid(props: DataGridPropType) {
    const onExporting = (e: any) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                FileSaver.saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    return (
        <React.Fragment>
            <DevDataGrid
                dataSource={props.source}
                sorting={{ mode: "multiple" }}
                showBorders={true}
                showColumnLines={true}
                //focusedRowEnabled={true}
                showRowLines={true}
                onToolbarPreparing={props.onToolbarPreparing}
                height={props.height}
                columnWidth={100}
                width={props.width}
                columnAutoWidth={false}
                onFocusedRowChanged={props.onFocusedRowChanged}
                selection={{ mode: "single" }}
                rowAlternationEnabled={false}
                columnResizingMode="widget"
                keyExpr={props.keyExpr}
                allowColumnResizing={true}
                allowColumnReordering={true}
                remoteOperations={true}
                onExporting={onExporting}
                onSelectionChanged={props.onSelectionChanged}
                {...props}>
                <FilterRow visible={props.showHeaderFilter} />
                <HeaderFilter visible={props.showHeaderFilter} />
                <GroupPanel visible={props.showGroupPanel} />
                <Scrolling mode="virtual" />
                <Editing
                    mode="batch"
                    selectTextOnEditStart={true}
                    allowUpdating={props.allowEditing}
                    allowDeleting={props.allowDeleting}
                    startEditAction="click"
                    allowAdding={props.allowAdding}
                />
                <Grouping autoExpandAll={false} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                <Export enabled={props.allowExport} allowExportSelectedData={true} />
                {props.children}
            </DevDataGrid>
        </React.Fragment>
    );
}

export const BusyIndicatorContext = React.createContext((busy: boolean, message: string) => { });

function sleeper(ms: number) {
    return function (x: any) {
        return new Promise((resolve) => setTimeout(() => resolve(x), ms));
    };
}

export function useBusy() {
    const setBusy = React.useContext(BusyIndicatorContext);
    return (msg: string = ""): void => {
        if (msg) {
            setBusy(true, msg);
        } else {
            setBusy(false, "");
        }
    };
}

export function useGetData() {
    const token = sessionStorage.getItem("token");

    return (url: string) => {
        return fetch(url, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
        })
            .then((response) => {
                return response.json();
            })
            .catch((err) => {
                return { error: err };
            });
    }; // parses JSON response into native JavaScript objects
}

export function usePostData() {
    const token = sessionStorage.getItem("token");

    // Default options are marked with *
    return (url: string, data: {}) => {
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then((response) => {
                return response.json();
            })
            .catch((err) => {
                return { error: err };
            });
    }; // parses JSON response into native JavaScript objects
}

export function addComma(num: number) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ",");
}
