import { Button, Popup, TextArea } from "devextreme-react";
import notify from "devextreme/ui/notify";
import * as React from "react";
import { Column, DataGrid, formatMonth, GetDataSource, Summary, TotalItem, useBusy, useGetData, usePostData } from "../DataGrid";

export function InspecDataCLM() {
  const [date, setDate] = React.useState(formatMonth(new Date(), -1));
  const [refresh, setRefresh] = React.useState(new Date());
  const get = useGetData();
  const setBusy = useBusy();
  const [dlgShow, setDlgShow] = React.useState(false);
  const [dlgData, setDlgData] = React.useState("");
  const source = React.useMemo(() => GetDataSource("InspecDataCLM", "Get/" + date), [date, refresh]);
  var post = usePostData();

  const getDataFromCLM = async () => {
    setBusy("CLMS에서 검수데이터를 가져옵니다.");
    const res = await get("/api/CLM/Sync/" + date);
    setBusy();

    if (res.error) {
      notify(res.error, "error", 3000);
    }
  };

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxTextBox",
        options: {
          width: 136,
          value: date,
          mask: "0000.00",
          onValueChanged: ({ value }: { value: string }) => {
            setDate(value);
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "add",
          text: "파일 업로드",
          onClick: () => {
            setDlgData("");
            setDlgShow(true);
          },
        },
      }
    );
  };

  const uploadHandler = async () => {
    try {
      setBusy("파일 업로드 중입니다...");
      var res = await post("/api/InspecDataCLM/Upload/" + date, { content: dlgData });
      setDlgData("");
      setDlgShow(false);
      setRefresh(new Date());

      if (res.error) {
        notify(res.error, "error", 5000);
      }
    } catch {}
    setBusy();
  };

  return (
    <div className="layoutRoot">
      <DataGrid source={source} showGroupPanel={false} allowEditing={false} allowExport={true} onToolbarPreparing={onToolbarPreparing} allowDeleting={true} allowAdding={false}>
        <Column dataField="Id" width={90} caption="Id"></Column>
        <Column dataField="DocMonth" width={90} caption="검수월"></Column>
        {/* <Column dataField="Demand" caption="수요"></Column> */}
        <Column dataField="Customer" width={220} caption="발주사코드"></Column>
        {/* <Column dataField="BuyerCode" caption="판매사코드"></Column> */}
        {/* 검수월/발주사코드/품번/수량/제품매출금액 /포장비금액 */}

        <Column dataField="PartNo" caption="고객품번" width={220}></Column>
        <Column dataField="Qty" width={150} caption="검수수량" format="###,##0"></Column>
        <Column dataField="SalesAmt" width={150} caption="제품매출금액" format="###,##0"></Column>
        <Column dataField="PackCost" width={150} caption="포장비금액" format="###,##0"></Column>

        <Column dataField="Created" caption="생성일" width={200}></Column>
        <Column></Column>

        <Summary>
          <TotalItem column="DocMonth" displayFormat="항목수: {0}" summaryType="count" />
          <TotalItem column="Qty" summaryType="sum" displayFormat="합계: {0}개" valueFormat="###,##0" />
          <TotalItem column="SalesAmt" summaryType="sum" displayFormat="합계: {0}개" valueFormat="###,##0" />
          <TotalItem column="PackCost" summaryType="sum" displayFormat="합계: {0}개" valueFormat="###,##0" />
        </Summary>
      </DataGrid>

      <Popup
        visible={dlgShow}
        onHiding={() => setDlgShow(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="글로비스/기타매출 업로드"
        width={700}
        height={450}>
        <div>제목줄까지 포함해서 붙여넣으십시오.</div>

        <TextArea
          height={240}
          onValueChanged={(e) => setDlgData(e.value)}
          style={{ marginTop: "0.5rem" }}
          value={dlgData}
          placeholder="발주사코드/품번/수량/제품매출금액/포장비금액"></TextArea>
        <div>
          <Button text="업로드" style={{ marginTop: "16px", marginLeft: "auto", marginRight: "auto" }} onClick={uploadHandler} />
        </div>
      </Popup>
    </div>
  );
}
