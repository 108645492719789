import * as React from "react";
import { Column, Lookup } from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { DataGrid, GetDataSource } from "../DataGrid";

interface PropType {}
interface StateType {}

export class User extends React.PureComponent<PropType, StateType> {
  dataSource: any;

  constructor(prop: PropType) {
    super(prop);

    const url = "/api/User";
    this.dataSource = GetDataSource("User");
  }

  public render() {
    const arr = ["권한요청", "관리자", "실무자", "방문자"];

    return (
      <div className="layoutRoot">
        <DataGrid source={this.dataSource}>
          <Column dataField="UserName" caption="사번"></Column>

          <Column dataField="DisplayName" caption="이름"></Column>

          <Column dataField="Duty" caption="직책"></Column>

          <Column dataField="Depts" caption="부서"></Column>

          <Column dataField="Role" caption="권한">
            <Lookup dataSource={arr} />
          </Column>

          {/* <Column dataField="IsActive" caption="사용"></Column> */}

          <Column dataField="Remark" caption="비고" allowFiltering={false} allowSorting={false}></Column>
          <Column />
        </DataGrid>
      </div>
    );
  }
}
