import * as React from "react";
import { DataGrid, Column, GetDataSource } from "../DataGrid";

export function Vendor() {
  return (
    <div className="layoutRoot">
      <DataGrid source={GetDataSource("Vendor")} showGroupPanel={false}>
        <Column dataField="Id" caption="Id" allowEditing={false}></Column>
        <Column dataField="Code" caption="업체코드"></Column>
        <Column dataField="Name" caption="업체명" width={180}></Column>
        <Column dataField="Comment" caption="관리참조사항" allowFiltering={false}></Column>
        <Column dataField="IsActive" caption="적용"></Column>
        <Column dataField="Remark" caption="비고" allowFiltering={false}></Column>
        <Column />
      </DataGrid>
    </div>
  );
}
