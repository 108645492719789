/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import * as React from "react";

import {
    Chart,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Legend,
    Margin,
    Series,
    Tooltip,
    Label,
    Format,
    Crosshair,
    ValueAxis,
    Strip,
    Title,
    Subtitle,
} from "devextreme-react/chart";
import { useGetData } from "../../DataGrid";

export function AreaChart({ month }: { month: string }) {
    const get = useGetData();
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        get("/api/home/SalesChart/" + month).then((res) => {
            setData(res);
        });
    }, [month]);

    return (
        <Chart palette="Harmony Light" dataSource={data}>
            <Title text={`매출 계획/실적 누계액 (${new Date().getFullYear()}년)`}>
                <Subtitle text="단위:억원, 세금계산서 마감기준" />
            </Title>
            <CommonSeriesSettings argumentField="Month" type="splinearea" />
            <Series valueField="Target" name="계획"></Series>
            <Series valueField="Actual" name="실적"></Series>
            <Margin bottom={20} />
            <Crosshair enabled={true}>
                <Label visible={true} />
            </Crosshair>

            <ArgumentAxis allowDecimals={true} valueMarginsEnabled={false} axisDivisionFactor={60}>
                <Label format="00월"></Label>
                <Strip startValue={4} endValue={7} color="rgba(247, 215, 148,.3)" />
                <Strip startValue={10} endValue={12} color="rgba(247, 215, 148,.3)" />
            </ArgumentAxis>
            <ValueAxis>
                <Label format="###,##0억"></Label>
            </ValueAxis>

            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
            {/* <Tooltip enabled={true} /> */}
            {/* <Export enabled={true} /> */}
        </Chart>
    );
}
