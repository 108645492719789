import { Button, Popup, TextArea } from "devextreme-react";
import notify from "devextreme/ui/notify";
import * as React from "react";
import { DataGrid, GetDataSource, Column, Lookup, ValueFormat, useBusy, usePostData, formatMonth } from "../DataGrid";

// 업무대행 관리비 산출/관리

export function SalesCost4() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [date1, setDate1] = React.useState(formatMonth(new Date(), -1));

    const [refresh, setRefresh] = React.useState(new Date());

    const datasource = React.useMemo(() => GetDataSource("SalesCost4", "Get/" + date + "-" + date1), [date, date1, refresh]);
    const post = usePostData();
    const setBusy = useBusy();

    const [dlgShow, setDlgShow] = React.useState(false);
    const [dlgData, setDlgData] = React.useState("");

    const uploadHandler = async () => {
        try {
            setDlgShow(false);
            setBusy("생성중입니다 잠시만 기다려 주십시오");
            var res = await post("/api/SalesCost4/Upload", { content: dlgData });
            if (res.error) throw res.error;
            setDlgData("");
        } catch (err) {
            notify(err, "error", 5000);
        }
        setBusy();
        setRefresh(new Date());
    };

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate(e.value),
                },
            },
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date1,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate1(e.value),
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "add",
                    text: "생성",
                    onClick: () => setDlgShow(true),
                },
            }
        );
    };

    return (
        <div className="layoutRoot">
            <DataGrid source={datasource} allowAdding={false} showGroupPanel={false} allowDeleting={true} onToolbarPreparing={onToolbarPreparing}>
                <Column dataField="Id" caption="Id"></Column>
                <Column caption="결산월" dataField="DocMonth"></Column>
                <Column caption="판매사" dataField="Buyer"></Column>
                <Column caption="발주사" dataField="CustomerName"></Column>

                <Column caption="결산유형" dataField="SalesType">
                    {/* <Lookup dataSource={["정상정산", "반품정산", "소급정산"]} allowClearing={true}  /> */}
                </Column>

                <Column caption="고객품번" dataField="PartNo"></Column>
                <Column caption="모비스 결산수량" dataField="Qty"></Column>
                <Column caption="모비스 단가" dataField="UnitPrice"></Column>
                <Column caption="모비스 매출금액" dataField="PriceAmt"></Column>
                <Column caption="모비스 관리비" dataField="MngCost"></Column>
                <Column caption="IHL 확정 지급비" dataField="MngCost2"></Column>
                <Column caption="관리비차이" calculateCellValue={(data: any) =>  data.MngCost - data.MngCost2 }></Column>

                <Column caption="비고" dataField="Remark"></Column>

            </DataGrid>

            <Popup
                visible={dlgShow}
                onHiding={() => setDlgShow(false)}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title="모비스 매출 결산 자료 업로드"
                width={700}
                height={450}>
                <div>제목줄까지 포함해서 붙여넣으십시오.</div>

                <TextArea
                    height={240}
                    onValueChanged={(e) => setDlgData(e.value)}
                    style={{ marginTop: "0.5rem" }}
                    value={dlgData}
                    placeholder="결산연월/발주사/결산유형/고객품번/모비스단가/모비스 결산수량/매출금액"></TextArea>
                <div>
                    <Button text="업로드" style={{ marginTop: "16px", marginLeft: "auto", marginRight: "auto" }} onClick={uploadHandler} />
                </div>
            </Popup>
        </div>
    );
}
