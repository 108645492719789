import * as React from "react";
import { DataGrid, Column, GetDataSource, Lookup, Summary, TotalItem, MasterDetail, formatMonth, formatDate, usePostData, useGetData, useBusy, useLoginInfo } from "../DataGrid";
import styled from "styled-components";
import Popup from "devextreme-react/popup";
import TextArea from "devextreme-react/text-area";
import Button from "devextreme-react/button";
import Form, { ButtonItem, Item } from "devextreme-react/form";
import notify from "devextreme/ui/notify";

const salesTypes = ["정상결산", "반품결산", "소급결산", "기간정산", "관세정산", "샘플/EO/투자비(전표)", "유상사급 매출", "샘플/EO/투자비(매출/입)","샘플/EO/투자비(매출)"];
const RefreshDispatch = React.createContext(() => { });
export function TaxInvoice() {
    const [date, setDate] = React.useState(formatMonth(new Date(), -1));
    const [date1, setDate1] = React.useState(formatMonth(new Date(), -1));

    const [show, setShow] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const login = useLoginInfo();

    const get = useGetData();
    const post = usePostData();
    const setBusy = useBusy();

    const handlerCheck = async () => {
        setBusy("수요를 추정합니다.");
        try {
            await get("/api/TaxInvoice/Check/" + date);
        } catch { }
        setBusy();

        setCount(count + 1);
    };

    const handlerSync = async () => {
        if (!window.confirm("전월 매출 세금계산서 목록을 가져옵니다.\n이 작업은 상당한 작업시간이 소요됩니다.\n\n진행하시겠습니까?")) {
            alert("작업이 취소되었습니다.");
            return;
        }
        try {
            setBusy("스마트빌 통신중...");
            await get("/api/TaxInvoice/Sync/" + date);
            setBusy("수요를 추정합니다.");
            await get("/api/TaxInvoice/Check/" + date);
        } catch { }
        setBusy();
        setCount(count + 1);
    };

    const handlerAddNew = async () => {
        setShow(true);
    };

    const dateValueChanged = (evt: any) => {
        setDate(evt.value);
    };
    const refresh = () => {
        setCount(count + 1);
    };

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date,
                    mask: "0000.00",
                    onValueChanged: dateValueChanged,
                },
            },
            {
                location: "before",
                widget: "dxTextBox",
                options: {
                    width: 80,
                    value: date1,
                    mask: "0000.00",
                    onValueChanged: (e: any) => setDate1(e.value),
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    visible: login.level == 2,
                    text: "스마트빌 연동 + 수요지정",
                    stylingMode: "contained",
                    onClick: handlerSync,
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    visible: login.level == 2,
                    text: "수요 재지정",
                    stylingMode: "contained",
                    onClick: handlerCheck,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "add",
                    visible: login.level == 2,
                    hint: "세금계산서 생성",
                    stylingMode: "contained",
                    onClick: handlerAddNew,
                },
            }
        );
    };

    return (
        <div className="layoutRoot">
            <RefreshDispatch.Provider value={refresh}>
                <MainGrid date={date} date1={date1} onToolbarPreparing={onToolbarPreparing} count={count}></MainGrid>
                <AddNewModel
                    show={show}
                    month={date}
                    onHiding={() => {
                        setShow(false);
                        setCount(count + 1);
                    }}
                />
            </RefreshDispatch.Provider>
        </div>
    );
}

type MainGridProps = {
    date: string;
    date1: string;
    onFocusedRowChanged?: (e: any) => void | undefined;
    onToolbarPreparing: (e: any) => void | undefined;
    children?: React.ReactNode | React.ReactNode[];
    count: number;
};

function MainGrid({ date, date1, onFocusedRowChanged, onToolbarPreparing, count }: MainGridProps) {
    const source = React.useMemo(() => GetDataSource("TaxInvoice", "Get/" + date + "-" + date1), [date, date1, count]);
    //const source =  GetDataSource("TaxInvoice", "Get/" + date);
    const customers = React.useMemo(() => GetDataSource("CodeMaster", "Customers"), [date]);
    const demands = React.useMemo(() => GetDataSource("CodeMaster", "Demands"), [date]);

    return (
        <>
            <DataGrid source={source} allowEditing={true} allowAdding={false} allowDeleting={true} onFocusedRowChanged={onFocusedRowChanged} onToolbarPreparing={onToolbarPreparing}>
                <Column allowEditing={true} dataField="DocMonth" caption="결산기준월" width={170}></Column>
                <Column allowEditing={false} dataField="BYR_COM_NAME" caption="판매사" width={170}></Column>

                <Column allowEditing={true} dataField="CustomerId" caption="발주사" width={250}>
                    <Lookup dataSource={customers} displayExpr="Name" valueExpr="Id" />
                </Column>

                <Column dataField="SalesType" caption="매출유형*">
                    <Lookup dataSource={salesTypes} />
                </Column>

                <Column dataField="Remark" caption="매출세부내역" allowEditing={true} allowFiltering={true} width={200}></Column>
                <Column allowEditing={false} dataField="DTI_TYPE" caption="영/과세" width={70}></Column>
                <Column allowEditing={false} dataField="DTI_WDAY" caption="거래일자"></Column>
                <Column allowEditing={false} dataField="ChargeTotalAmount" format="###,##0" caption="공급가액" width={230}></Column>
                <Column allowEditing={false} dataField="TaxTotalAmount" format="###,##0" caption="세액" width={130}></Column>
                <Column allowEditing={false} calculateCellValue={(data : any)=> data.ChargeTotalAmount + data.TaxTotalAmount} format="###,##0" caption="합계금액" width={130}></Column>


                <Column allowEditing={false} dataField="IsDetail" caption="거래명세서 접수" width={80}></Column>
                <Column allowEditing={false} dataField="IssuedByName" caption="고객사담당자" width={140}></Column>
                <Column allowEditing={false} dataField="IssuedBy" caption="메일주소" width={200}></Column>
                <Column allowEditing={false} dataField="ISSUE_ID" caption="세금계산서 승인번호" width={210} />

                <Column allowEditing={true} dataField="PONo" caption="구매확인서" width={210} />

                <Column allowEditing={true} dataField="DocNo" caption="전표번호" />
                <Column allowEditing={true} dataField="Remark2" caption="비고" />



                <Column allowEditing={true} dataField="IHLTaxNo" caption="IHL세금계산서" width={210} />

                <Column />
                <MasterDetail autoExpandAll={false} enabled={true} component={DetailTemplate} />
                <Summary>
                    <TotalItem column="ISSUE_ID" displayFormat="항목수: {0}" summaryType="count" />
                    <TotalItem column="ChargeTotalAmount" summaryType="sum" displayFormat="{0}원" valueFormat="###,##0" />
                </Summary>
            </DataGrid>
        </>
    );
}

function AddNewModel({ show, onHiding, month }: { show: boolean; onHiding: () => void; month: string }) {
    const [customers, setCustomers] = React.useState([]);
    const get = useGetData();
    const post = usePostData();

    React.useEffect(() => {
        get("/api/CodeMaster/Customers").then((res) => {
            setCustomers(res.data);
        });
    }, [0]);

    //승인번호,작성일자,발주처,수요구분,매출구분,과세,정/역구분, 비고
    const [busy, setBusy] = React.useState(false);

    const initData = {
        승인번호: "",
        작성일자: formatDate(),
        결산월: month,
        발주사: 0,
        매출구분: undefined,
        "정/역발행": "역",
        공급가액: 0,
        세액: 0,
        비고: "",
    };

    const [data, setData] = React.useState(initData);

    const handlerSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setBusy(true);
        var res = await post("/api/TaxInvoice/Create", data);
        setBusy(false);

        if (res.error) {
            notify(res.error, "error", 15000);
            return;
        }
        setData({ ...initData });
        onHiding();
    };

    const requiredRule = [{ type: "required", message: "필수 입력사항입니다." }];

    return (
        <Popup visible={show} dragEnabled={false} onHiding={onHiding} closeOnOutsideClick={true} showTitle={true} title="세금계산서 생성" width={500} height={450}>
            <form onSubmit={handlerSubmit}>
                <Form
                    style={{ margin: "16px 16px 10px 16px" }}
                    id="form"
                    formData={data}
                    readOnly={false}
                    onContentReady={(e: any) => {
                        e.component.validate();
                    }}
                    showColonAfterLabel={true}
                    labelLocation="top"
                    minColWidth={300}
                    colCount={2}>
                    <Item dataField="승인번호" validationRules={requiredRule} />
                    <Item dataField="작성일자" validationRules={requiredRule} editorOptions={{ mask: "0000-00-00" }} />
                    <Item
                        dataField="발주사"
                        validationRules={requiredRule}
                        editorType="dxSelectBox"
                        editorOptions={{ items: customers, searchEabled: true, displayExpr: "Name", valueExpr: "Id" }}
                    />

                    <Item dataField="매출구분" validationRules={requiredRule} editorType="dxSelectBox" editorOptions={{ items: salesTypes, searchEabled: true }} />

                    <Item dataField="정/역발행" validationRules={requiredRule} editorType="dxSelectBox" editorOptions={{ items: ["정", "역"], searchEabled: true }} />

                    <Item dataField="공급가액" validationRules={requiredRule} />
                    <Item dataField="세액" validationRules={requiredRule} />
                    <Item dataField="비고" colSpan={2} />
                    <ButtonItem
                        horizontalAlignment="left"
                        buttonOptions={{
                            text: "저장",
                            type: "default",
                            width: "130px",
                            useSubmitBehavior: true,
                        }}
                    />
                </Form>
            </form>
        </Popup>
    );
}

function DetailTemplate({ data }: { data: any }) {
    const refresh = React.useContext(RefreshDispatch);
    const get = useGetData();
    const post = usePostData();

    const TaxInvoiceTable = styled.table`
    & {
      margin-bottom: 10px;
      border: 1px solid #cccccc;
      border-collapse: collapse;
    }
    & > tr > * {
      border: 1px solid #cccccc;
    }
    & th {
      font-weight: bold;
      background-color: #efefef;
      padding: 6px 30px 6px 16px;
    }
    & td {
      padding: 6px 30px 6px 16px;
    }
  `;

    const id = data.data.Id;
    const title = data.data.ISSUE_ID;
    const tax = data.data;
    const source = React.useMemo(() => GetDataSource("TaxInvoice", "Detail/" + id), [id]);
    const [show, setShow] = React.useState(false);

    const onToolbarPreparing = (e: any) => {
        e.toolbarOptions.items.unshift({
            location: "berfore",
            widget: "dxButton",
            options: {
                width: 236,
                text: "상세거래 명세서 업로드",
                stylingMode: "contained",
                onClick: () => {
                    setShow(true);
                },
            },
        });
    };

    const handlerUpload = () => {
        post("/api/TaxInvoice/UploadInvoiceDetail", { key: title, content: content }).then((res) => {
            if (res.message != undefined) {
                alert(res.message);
            } else {
                setContent("");
                setShow(false);
                refresh();
            }
        });
    };

    const [content, setContent] = React.useState("");

    return (
        <div>
            <TaxInvoiceTable>
                <tbody>
                    <tr>
                        <th>승인번호</th>
                        <td>{title}</td>
                        <th>작성일자</th>
                        <td>{tax.DTI_WDAY}</td>
                        <th>정/역 구분</th>
                        <td>{tax.DIRECTION}</td>
                    </tr>
                    <tr>
                        <th>사업자명</th>
                        <td>{tax.BYR_COM_NAME}</td>
                        <th>사업자번호</th>
                        <td>{tax.BYR_COM_REGNO}</td>
                        <th>고객담당자</th>
                        <td>
                            {tax.IssuedByName}({tax.IssuedBy})
            </td>
                    </tr>
                    <tr>
                        <th>상태</th>
                        <td>{tax.DTI_STATUS}</td>
                        <th>공급가액</th>
                        <td>{tax.ChargeTotalAmount}</td>
                        <th>총액</th>
                        <td>{tax.GrandTotalAmount}</td>
                    </tr>
                </tbody>
            </TaxInvoiceTable>

            <DataGrid
                height="default"
                onToolbarPreparing={onToolbarPreparing}
                source={source}
                allowExport={false}
                allowAdding={false}
                allowDeleting={false}
                allowEditing={false}
                width="default"
                showHeaderFilter={false}>
                <Column dataField="Seq" caption="순서" sortOrder="asc"></Column>
                <Column dataField="PartNo" caption="품번" width={120}></Column>
                <Column dataField="Name" caption="품명" width={220}></Column>
                <Column dataField="UnitQty" caption="수량" format="###,##0"></Column>
                <Column dataField="UnitPrice" caption="매출검수단가" format="###,##0.##"></Column>
                <Column dataField="Amount" caption="매출검수금액" format="###,##0.##" width={240}></Column>
                <Column dataField="Desc" caption="비고"></Column>
                <Summary>
                    <TotalItem column="Seq" displayFormat="항목수: {0}" summaryType="count" />
                    <TotalItem column="UnitQty" summaryType="sum" displayFormat="{0}" valueFormat="###,##0" />
                    <TotalItem column="Amount" summaryType="sum" displayFormat="{0}원" valueFormat="###,##0" />
                </Summary>
            </DataGrid>

            <Popup
                visible={show}
                dragEnabled={false}
                onHiding={() => {
                    setShow(false);
                }}
                closeOnOutsideClick={true}
                showTitle={true}
                title="거래명세서 업로드"
                width={500}
                height={500}>
                <div className="form">
                    <div className="dx-fieldset">
                        <div className="dx-fieldset-header">
                            제목줄 포함해서 엑셀에서 붙여넣으십시오(고객품번 / 금액 / 수량)
              <br />
                            <span style={{ color: "Red", fontSize: "0.8em" }}>등록된 내용을 지우고 입력하니 주의요함</span>
                        </div>
                        <div className="dx-field">
                            <div className="dx-field-label">승인번호</div>
                            <div className="dx-field-value-static">{title}</div>
                        </div>
                        <div className="dx-field">
                            <TextArea
                                height={180}
                                value={content}
                                onValueChanged={(e: any) => {
                                    setContent(e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button text="업로드" stylingMode="contained" width={130} onClick={handlerUpload} />
                    </div>
                </div>
            </Popup>
        </div>
    );
}
